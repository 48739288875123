/* :root {

    --bgDark: #0E0E2C;

    --bgDark1: #0E0E2C;

    --bgDarkGrey:#313C46;

    --bgDarkGrey-95:rgba(49,60,70,0.75);

    --bgGrey: #515A64;

    --bgGreen: #389738;

    --bgDarkGreen: #4DB205;

    --bgDarkRed: #FF2400;

    --bgBlack: #000000;

    --bgYellow: #FFDB1A;

    --bgDarkYellow:#e0bb02;

    --bgWhiteSpin: #FFFFFF;

    --bgWhite30:#FAFCFE;

    --bgOrient: #1E526E;

    --bgOrient1: #1E526E;

    --bgGloom: #243540;

    --bgBlue: #1E90FF;

    --bgSwapFrom: #1B4156;

    --bgSwapTo: #1E526E;

    --bgPitch1: #1B4156;

    --bgBlu: #296281;

    --bgOrion: #13132B;

    --textPureWhite: #FFFFFF;

    --textWhite: #FFFFFF;

    --textWhite30: #FAFCFE;

    --textWhite50: #EEF2F5;

    --textSecondary: #9797AA;

    --textDark: #0E0E2C;

    --textGreen: #4DB205;

    --textYellow: #FFDB1A;

    --textOrange: #FF9800;

    --textDefautlYlw: #FFDB1A;

    --textRed: #FF2400;

    --textAqua: #2AAECC;
    --textpurple:#5C5C77;

    --textDarkGreen: #4DB205;

    --textError: #FF2400;

    --btnGrad1: #B30F1E;

    --btnGrad2: #0038FF;

    --btnBorder: #5C5C77;

    --borderGrey: #313C46;

    --borderLight: #9797AA;

    --borderDark: #2d2d2d;

    --bgCircle: #19B06C;
    --lightThemeIcons: url(https://prduximagestorage.blob.core.windows.net/suissebaseimages/user-dark-theme-icons.svg);

    --cryptoIcons:url(https://prduximagestorage.blob.core.windows.net/suissebaseimages/dark-crypto-icons.svg);

    --logo: url(../../assets/images/logo-white.png);
} */

:root {

    --bgDark: #0E0E2C;
    --bgDark1: #0E0E2C;
    --bgDarkGrey:#313C46;
    --bgDarkGrey-95:rgba(49,60,70,0.75);
    --bgGrey: #515A64;
    --bgGreen: #389738;
    --bgBlack: #000000;
    --bgYellow: #FFDB1A;
    --bgDarkYellow:#e0bb02;
    --bgWhite: #FFFFFF;
    --bgWhiteSpin: #FFFFFF;
    --bgWhite30:#FAFCFE;
    --bgOrient: #1E526E;
    --bgOrient1: #1E526E;
    --bgGloom: #243540;
    --bgSwapFrom: #1B4156;
    --bgSwapTo: #1E526E;
    --bgPitch1: #1B4156;
    --bgBlu: #296281;
    --bgOrion: #13132B;
    --textPureWhite: #FFFFFF;
    --textWhite: #FFFFFF;
    --textWhite30: #FAFCFE;
    --textWhite50: #EEF2F5;
    --textSecondary: #9797AA;
    --textDark: #0E0E2C;
    --textGreen: #4DB205;
    --textYellow: #FFDB1A;
    --textDefautlYlw: #FFDB1A;
    --textRed: #FF2400;
    --textAqua: #2AAECC;
    --textpurple:#5C5C77;
    --textDarkGreen: #4DB205;
    --textError: #FF2400;
    --btnGrad1: #B30F1E;
    --btnGrad2: #0038FF;
    --btnBorder: #5C5C77;
    --borderGrey: #313C46;
    --borderLight: #9797AA;
    --borderDark: #2d2d2d;
    --bgCircle: #19B06C;
    --bgDarkGrey30:#313C46;



    --black: #000000 ;
    --primary: #E8E9EE;
    --white:#FFFFFF;
    --secondary: #08090E;
    --faitGradGreen: linear-gradient(180deg, #88C351 -167.81%, #081100 100%);
    --faitGradeBlue:linear-gradient(180deg, #627EEA -115.75%, #00061d 100%);
    --sbCard: linear-gradient(116.55deg, #79001C 18.81%, #0E0411 61.78%, #203386 86.7%);
    --profileMenu: #4E505A;
    --green: #1AFD90;
    --viewAll:#1AFD90;
    --drawerBG:#3C3C42;
    --alertBG:#3c3c42;
    --drawerMask:#00000095;
    --drawerList:rgba(255, 255, 255, 0.1);
    --searchBg:rgba(21, 21, 25, 0.6);
    --grid:rgba(0,0,0,0.4);
    --marketTitle:rgba(255, 255, 255 ,0.6);
    --cardBG: rgba(255, 255, 255 ,0.15);
    --menuFont: rgba(255, 255, 255 ,0.9);
    --coinPrice: rgba(250, 252, 254 ,0.8);
    --sbCardText: rgba(255, 255, 255 ,0.6);
    --dbTitle:rgba(255, 255, 255, 0.6);
    --subText: rgba(255, 255, 255 ,0.6);
    --tableText: rgba(255, 255, 255 ,0.5);
    --tablesub:rgba(255, 255, 255, 0.9);
    --tableInner:rgba(255, 255, 255, 0.4);
    --tablecol:#E9E9E9;
    --tableborder:rgba(255, 255, 255,0.2);
    --bodyBg: #000000 ;
    --button: #3653E4;
    --coinBal: #FAFCFE;
    --iconBg: #3C3C3C;
    --red: #FF6F6F;
    --placeholders:rgba(255, 255, 255 ,0.5);
    --coinBg:rgba(255, 255, 255, 0.2);
    --drpdwnHover:rgba(255, 255, 255 ,0.15);
    --inputBg:rgba(21, 21, 25,0.4);
    --checkText:rgba(255, 255, 255, 0.9);
    --checksub:rgba(255, 255, 255 ,0.7);
    --borderColor:rgba(255, 255, 255,0.2);
    --termsText:#CCC3FF;
    --inputBorder:rgba(255, 255, 255, 0.6);
    --balInput:#FAFCFE;
    --radioFill:rgba(92, 97, 255, 0.35);
    --radioBorderfill:#CCC3FF;
    --radioOutlinefill:rgba(255, 255, 255, 0.1);
    --radioBg:rgba(255, 255, 255 ,0.15);
    --paraBg:rgba(255, 255, 255 ,0.7);
    --accountType:#343E3A;
    --PriceIncrease:#286B4B;
    --priceVal:#33FB94;
    --priceDecrease:#4D3030;
    --priceRed:#FF3A1A;
    --addressList:rgba(255, 255, 255, 0.1);
    --tabBg:rgba(8, 9, 14,0.9);
    --tabLine:#CCC3FF;
    --userMenubg:rgba(8, 9, 14,0.4);
    --profiletab:#5C61FF;
    --gridheader:rgba(255, 255, 255, 0.1);
    --pfsmbtn:rgba(255, 255, 255, 0.3);
    --btnhover:#ffffff;
    --profilebgImg:rgba(255, 255, 255,0.2);
    --tablebody:#2c2c30;
    --gridborder:rgb(255 255 255 / 15%);
    --gridtitle:rgba(255, 255, 255, 0.6);
    --gridcontent:rgba(255, 255, 255, 0.8);
    --popupbg:#55555B;
    --coindate:#74767D;
    --coinviewradio:rgba(255, 255, 255, 0.1);
    --coinViewborder:#4E505A;
    --bgDarkGreen: #4DB205;
    --bgBlue: #1E90FF;
    --bgDarkRed: #FF2400;
    --labelBorder:rgba(255, 255, 255, 0.4);
    --swapBg:rgba(8, 9, 14, 0.5);
    --swapvalBg:rgba(21, 21, 25, 0.4);
    --radiocheccolor:rgba(250, 252, 254, 0.8);
    --upload: #4E505A;
    --addHover: rgba(92, 97, 255, 0.35);
    --scroll:rgba(255, 255, 255 ,0.15);

    --lightThemeIcons: url(https://prduximagestorage.blob.core.windows.net/suissebaseimages/user-darktheme-icons.svg);
    --cryptoIcons: url(https://prduximagestorage.blob.core.windows.net/suissebaseimages/light-crypto-icons.svg);
    --logo: url(../../assets/images/logo-color.png);
}
body{background:var(--bodyBg) url(dark-bg2.0.svg) no-repeat top center ;}
/* .sb-card{background:url(sb-card.png)  no-repeat top center;} */